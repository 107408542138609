<template>
  <b-row>
    <b-col v-for="item in items" :key="item.id" cols="auto">
      <StrapiLettreTechniqueComponent :item="item">
      </StrapiLettreTechniqueComponent>
    </b-col>
  </b-row>
</template>

<script>
import StrapiLettreTechniqueComponent from "./StrapiLettreTechniqueComponent.vue";
import StrapiService from "../../services/strapi.service";
import UtilsService, { SortDirection } from "../../services/utils.service";
export default {
  name: "StrapiCategorieLettreTechniqueComponent",
  props: ["item"],
  components: { StrapiLettreTechniqueComponent, },
  data: () => ({ items: [], }),
  async mounted() {
    // Si la catégorie fournie est nulle, on ne fait rien
    if (!this.item?.data?.id) {
      return;
    }

    // Si l'utilisateur n'est pas autorisé, on quitte la page
    if (!this.$store.getters['auth/hasAccesStrapiLettres']) {
      this.$router.push({ name: 'accueil' });
    }

    // Sinon on charge la catégorie de documents à consulter
    let response = await StrapiService.getLettresTechniques(
      this.item.data.id
    );
    this.items = response.data.data.sort(
      UtilsService.sortBy(
        UtilsService.sortByNumberProperty(
          "attributes.ordre",
          SortDirection.ASC
        ),
        UtilsService.sortByStringProperty(
          "attributes.publishedAt",
          SortDirection.DESC
        )
      )
    );
  },
};
</script>
