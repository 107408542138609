<template>
  <b-container v-if="page" fluid>
    <!-- Affichage de l'entête de page -->
    <StrapiMarkdownComponent
      :item="page.attributes.entete"
    ></StrapiMarkdownComponent>
    <!-- Affichage du contenu sur mesure -->
    <template v-for="(item, index) in page.attributes.contenu">
      <component
        :key="index"
        :is="getComponent(item.__component)"
        :item="item"
      ></component>
    </template>
    <!-- Affichage en mode collection -->
    <StrapiCollectionComponent :collection="collection"></StrapiCollectionComponent>
    <!-- Affichage spécifique pour les lettres techniques -->
    <StrapiCategorieLettreTechniqueComponent
      :item="page.attributes.categorielettre"
    ></StrapiCategorieLettreTechniqueComponent>
  </b-container>
</template>

<script>
import StrapiMarkdownComponent from "../../components/strapi/StrapiMarkdownComponent.vue";
import StrapiCategorieLettreTechniqueComponent from "../../components/strapi/StrapiCategorieLettreTechniqueComponent.vue";
import StrapiCollectionComponent from "../../components/strapi/StrapiCollectionComponent.vue";
import StrapiLigneComponent from "../../components/strapi/StrapiLigneComponent.vue";
import StrapiCarrouselComponent from "../../components/strapi/StrapiCarrouselComponent.vue";
export default {
  name: "StrapiPageView",
  components: {
    StrapiMarkdownComponent,
    StrapiCategorieLettreTechniqueComponent,
    StrapiCollectionComponent,
    StrapiLigneComponent,
    StrapiCarrouselComponent,
  },
  data() {
    return {
      page: null,
    };
  },
  computed: {
    collection() {
      return this.$store.getters["strapi/collection"](
        this.page?.attributes.collection
      );
    },
  },
  mounted() {
    this.load();
  },
  watch: {
    $route() {
      this.load();
    },
  },
  methods: {
    getComponent(code) {
      return this.$store.getters["strapi/composant"](code)?.component;
    },
    async load() {
      this.page = null;
      await this.$nextTick();
      this.page = this.$store.getters['strapi/page'](this.$route.params.id);
    },
  },
};
</script>
